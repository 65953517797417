<template>
  <div>
    <Modal :options="{width: '80vw', close: true}" @close="$emit('close')">
        <div class="title">Información</div>
        <div class="body">
          <Direcciones :informacion_cliente="informacion_cliente" @direccion="direccion"/>
          <Emails :informacion_cliente="informacion_cliente" @email="email"/>
          <Telefonos :informacion_cliente="informacion_cliente" @telefono="telefono"/>
          <Creditos :informacion_cliente="informacion_cliente" @credito="credito"/>
        </div>
        <div class="footer">
            <div class="row">
                <div class="col-sm-3 mt-2"> <strong>Selecciona la información a importar</strong></div>
                <div class="col-sm-4 offset-1"><button class="btn btn-block btn-success" @click="nueva_direccion">Agregar una direccion o método de contacto</button></div>
                <div class="col-sm-2"><button class="btn btn-block btn-primary" @click="seleccionar_informacion">Importar Información</button></div>
                <div class="col-sm-2"><button class="btn btn-block btn-danger" @click="$emit('close')">Cancelar</button></div>
            </div>
        </div>        
    </Modal>    
  </div>
</template>

<script>
import Modal from '@/components/Modal';
import Direcciones from "./Direcciones";
import Emails from "./Emails";
import Telefonos from "./Telefonos";
import Creditos from "./Creditos";

export default {
  components:{
      Modal, Direcciones, Emails, Telefonos, Creditos
  },
  props:{
    informacion:{
      type: Array,
      required: true,
    },
    metodo_contacto:{
      type:Array,
      required:true,
    }
  },
  data(){
    return{
      informacion_cliente:{
        direcciones:[],
        emails:[],
        telefonos:[],
        creditos:[],
      },
      informacion_seleccionada:{
        direccion:null,
        email:null,
        telefono:null,
        folio_credito:null,
      },      
      metodos_contacto_org:[],
    }
  },
  methods:{
    procesar_informacion(){
      this.informacion.forEach(element => {
        this.informacion_cliente.direcciones = element.direcciones;
        this.metodos_contacto_org = element.contactos;
        this.informacion_cliente.creditos = element.creditos;
      });
    },
    separar_metodos_contacto(){
      this.metodos_contacto_org.forEach(contacto => {
          if(contacto.tipo.includes('Teléfono'))
              this.informacion_cliente.telefonos.push(contacto);
          else
              this.informacion_cliente.emails.push(contacto);
      });     
    },
    formatear_metodo_contacto(){
      this.metodo_contacto.forEach(contacto => {
        this.metodos_contacto_org.map(element => (element.tipo === contacto.valor) && (element.tipo = contacto.nombre));
      });     
    },
    direccion(data){
      // this.$log.info('direccion seleccionada ', data);
      this.informacion_seleccionada.direccion = data;
    },
    email(data){
      // this.$log.info('email seleccionado ', data);
      this.informacion_seleccionada.email = data;
    },
    telefono(data){
      // this.$log.info('telefono seleccionado ', data);
      this.informacion_seleccionada.telefono = data;
    },
    credito(data){
      // this.$log.info('crédito seleccionado ', data);
      this.informacion_seleccionada.folio_credito = data;
    },
    seleccionar_informacion(){
      if(!Object.values(this.informacion_seleccionada).some(contacto => (contacto !== null && contacto !== '')))
          return this.$helper.showMessage('Error','Para importar información debes seleccionar al menos una de las opciones','error','alert');
          
      this.$emit('informacion', this.informacion_seleccionada);
      this.informacion_seleccionada = null;
      this.$emit('close');
      this.$helper.showMessage('Información importada','Los datos se han importado con éxito','info', 'alert');            
    },
    nueva_direccion(){
      this.$emit('close');
      this.$emit('nueva_direccion');
      this.$helper.showMessage('Nueva Dirección','Aquí puedes administrar las diferentes formas de contacto del cliente','info','alert');            

    }
  },
  beforeMount(){
    this.procesar_informacion();
    this.formatear_metodo_contacto();
    this.separar_metodos_contacto();
  }
}
</script>
