<template>
  <div>
    <fieldset>
    <legend>Créditos</legend>
        <div class="row form-group">
            <div class="col-sm-10 texto-menor offset-1">
                <div class="row header">
                    <div class="col-sm-10">Folio de Crédito</div>
                    <div class="col-sm-2">Seleccionar</div>
                </div>
                <template v-if="informacion_cliente.creditos.length > 0">
                <div v-for="credito in informacion_cliente.creditos" :key="credito.id" class="row row_observation">
                    <div  class="col-sm-9">
                        <strong>Folio: </strong> {{ credito.folio }}
                    </div>
                    <div class="col-sm-3 text-center"> <input v-model="credito_seleccionado" class="form-check-input" type="radio" :id="credito.id" :value="credito.folio"/></div>
                </div>
                </template>
                <div v-else class="row">
                    <div class="col-sm-12">No hay créditos</div>
                </div>
            </div>
        </div>
    </fieldset>    
  </div>
</template>

<script>
export default {
    props:{
        informacion_cliente:{
            type:Object,
            required:true,
        }
    },
    data(){
        return{
            credito_seleccionado:null,
        }
    },
    // methods:{
    //     actualizar_direccion(){
    //         this.$emit('credito', this.credito_seleccionado);
    //     }
    // },
    watch:{
        credito_seleccionado(){
            // this.actualizar_direccion();
            this.$emit('credito', this.credito_seleccionado);
        }
    }
}
</script>

<style lang="scss" scoped>
  .file_list_container {
    overflow: auto;

    .file_list {
      button {
        margin: 3px;
      }
    }

    .file_list:nth-child(2n+1) {
      background-color: #E6E6E6;
    }
  }

  .header {
    background-color: #4D4D4D;
    color: #fff;
    padding: 5px 3px;
  }

  .texto-menor {
    font-size: 0.8em !important;

    button {
      font-size: 0.8em !important;
    }
  }

  .row_observation {
    div {
      margin-top: 5px;
      margin-bottom: 5px;
    }
  }

  .row_observation:nth-child(2n+1) {
    background-color: #E6E6E6;
  }
  
</style>